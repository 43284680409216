import React, { useState, useEffect, useRef } from 'react'
import Header from './Header'
import Photo from './Photo'
import { layoutStyle, exportSvg } from '../../utils'
import Title from '../Title'
import { print, printerAlert, printerStatus } from '../../api'
import Button from '../Button'

export default function Print({ photos, config, cleanSession }) {
  const [queue, setQueue] = useState([...photos.slice(1)])
  const [current, setCurrent] = useState(photos[0])
  const [error, setError] = useState(false)
  const svgRef = useRef(null)
  const prints = photos.map(p => p.quantity).reduce((curr, acc) => curr + acc, 0)
  console.log(photos)
  const renderPhoto = () => {
    if (!current) return 
    setTimeout(async () => {
      const base64 = await exportSvg(svgRef.current)
      await Promise.all(
        Array.from(Array(current.quantity)).map(() => 
          print(base64.replace(/data:image\/(jpeg|png);base64,/, ''), 1, current.product.print).catch(e => console.log(e))
        )
      )
      console.log(base64.length, queue)
      if (queue.length > 0) setCurrent(queue.shift())
    }, 500)
  }
  const reprintAll = () => {
    setError(false)
    setQueue([...photos.slice(1)])
    setCurrent(null)
    setTimeout(() => setCurrent(photos[0]), 500)
  }
  const checkPrinter = async () => {
    const { status, error: printerError } = await printerStatus()
    console.log(status, printerError)
    if (printerError && !status)
      throw `${config.name} printer: ${printerError}`
    if (status && !status.match(/Idle|Printing/)) {
      setError(status)
    } else if (error && error !== 'solved') {
      setError('solved')
    }
  }
  useEffect(() => { renderPhoto() }, [current])
  useEffect(() => {
    if (error && error !== 'solved') printerAlert(config, error)
    const timeout = error ? null : setTimeout(cleanSession, 13 * 1000 * prints + 7000)
    const interval = setInterval(checkPrinter, 15000)
    return () => {
      clearInterval(interval)
      clearTimeout(timeout)
    }
  }, [error])
  return (
    <div className={layoutStyle} style={{ paddingTop: '2.5rem' }}>
      {error && error !== 'solved' &&
        <>
          <Title className="text-center mb-5">Oups, malheureusement nous allons manquer de papier pour cette commande</Title>
          <p className="text-center">Vous pouvez appeler le 01 84 60 81 60</p>
        </>
      }
      {error && error === 'solved' &&
        <>
          <Title className="text-center mb-5">Imprimante opérationnelle</Title>
          <Button className="m-2" onClick={reprintAll}>Réimprimer la commande</Button>
          <Button className="m-2" onClick={cleanSession}>Finaliser la session</Button>
        </>
      }
      {!error &&
        <>
          <Title className="text-center mb-5">Merci de votre commande et à bientôt</Title>
          <p>Impression de {prints} photo(s) en cours</p>
        </>
      }
      {current && <Photo photo={current} size={1000} svgRef={svgRef} className="absolute opacity-0 -z-10" render />}
      <img src="/print.png" className="mt-4 h-48" />
    </div>
  )
}
