import React, { useState } from 'react'
import Button from './Button'
import { FiArrowLeft } from 'react-icons/fi'

export default function Keyboard({ onType, type = 'simple' }) {
  const [text, setText] = useState('')
  const setTextAndCallback = t => {
    setText(t)
    onType(t)
  }
  const hitKey = key => setTextAndCallback(text + key)
  const removeKey = () => setTextAndCallback(text.substr(0, text.length - 1))
  return (
    <div className="px-4">
      <div className={`${type === 'email' ? 'text-xl' : 'text-3xl'} px-1 font-thin tracking-widest flex justify-between items-center mb-2`}>
        <div className="flex-grow h-8 mr-4 flex items-center leading-none relative overflow-hidden w-64">{text}</div>
        <Button Icon={FiArrowLeft} variant="smallIcon" style={{ padding: '0.44rem' }}  onClick={removeKey} />
      </div>
      <div className="flex flex-wrap items-center justify-center">
        {'1234567890'.split('').map(key => (
          <Button variant="smallIcon" key={key} style={{ margin: '.1rem', padding: '0.44rem' }} onClick={() => hitKey(key)}>{key}</Button>
        ))}
      </div>
      <div className="flex flex-wrap items-center justify-center">
        {'AZERTYUIOPQSDFGHJKLMWXCVBN'.split('').map(key => (
          <Button variant="smallIcon" key={key} style={{ margin: '.1rem', padding: '0.44rem' }} onClick={() => hitKey(type === 'email' ? key.toLowerCase() : key)}>{key}</Button>
        ))}
        {type === 'email' && '@.-_'.split('').map(key => (
          <Button variant="smallIcon" key={key} style={{ margin: '.1rem', padding: '0.44rem' }} onClick={() => hitKey(key)}>{key}</Button>
        ))}
      </div>
      {type === 'text' &&
        <div className="flex flex-wrap items-center justify-center">
          <Button variant="secondary" style={{ margin: '.5rem', padding: '0.25rem 2rem' }} onClick={() => hitKey(' ')}>ESPACE</Button>
        </div>
      }
      {type === 'email' &&
        <div className="flex flex-wrap items-center justify-center">
          {['@GMAIL.COM', '@HOTMAIL.COM', '@YAHOO.FR'].map(key => (
            <Button variant="secondary" className="bg-blue-light text-blue pr-3 pl-3" key={key} style={{ margin: '.1rem' }} onClick={() => hitKey(key.toLowerCase())}>{key}</Button>
          ))}
        </div>
      }
    </div>
  )
}
