import React, { useEffect, useState } from 'react'
import { print, printerAlert, printerStatus } from '../../api'
import Button from '../Button'
import Title from '../Title'
import { useDoubleTap } from 'use-double-tap'
import { formatPrice } from '../../utils'

export default function Home({ startSession, config, logo, admin }) {
  const tapBind = useDoubleTap(admin)
  const [error, setError] = useState(false)
  const checkPrinter = async () => {
    const { status, error: printerError } = await printerStatus()
    if (printerError && !status)
      throw `${config.name} printer: ${printerError}`
    if (status && !status.match(/Idle|Printing/)) {
      setError(status)
    } else if (error) {
      setError(null)
    }
  }

  useEffect(() => {
    if (error) printerAlert(config, error)
    const interval = setInterval(checkPrinter, 15 * 60 * 1000)
    return () => { clearInterval(interval) }
  }, [error])
  useEffect(() => { setTimeout(checkPrinter, 2000) }, [])  

  if (error)
    return (
      <div className="w-screen h-screen px-10 py-10 flex flex-col justify-center items-center text-center">
        <Title className="text-3xl">Imprimante non opérationnelle</Title>
        <p className="font-bold mt-4 opacity-50">Code erreur</p>
        <p className="text-sm font-mono mb-4 opacity-50">{error}</p>
      </div>
    )
  
  return (
    <div className="relative bg-blue-light w-screen h-screen px-10 pb-24 pt-12 flex flex-col justify-end items-center" style={{ zIndex: 60, backgroundImage: 'url(/background.jpg)' }}>
      <div {...tapBind} className="absolute top-0 right-0 w-32 h-32 z-30 cursor-pointer" />
      <Button
        variant="primary"
        onClick={startSession}>
        J'imprime mes photos
      </Button>
      {config.price > 0 &&
        <div className="text-sm mt-2 text-center text-blue">À partir de {formatPrice(config.price)} par photo</div>
      }
    </div>
  )
}
