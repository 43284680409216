import React, { useRef, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import { useDrag } from 'react-use-gesture'

const computeTemplate = ({ width: pW, height: pH, border, product: { width, height, bleed } }) => (
  pW > pH
  ? { width, height, bleed, photo: { x:  border ? 60 : 0, y:  border ? 60 : 0, width: border ? width - 120 : width, height: border ? height - 120 : height } }
  : { width: height, height: width, bleed, photo: { x: border ? 60 : 0, y:  border ? 60 : 0, width: border ? height - 120 : height, height: border ? width - 120 : width } }
)

const defaultCrop = (image, template) => {
  const imageRatio = image.width / image.height
  const formatRatio = template.photo.width / template.photo.height
  const width = imageRatio > formatRatio ? image.height * formatRatio : image.width
  const height = imageRatio > formatRatio ? image.height : image.width / formatRatio
  const x = imageRatio > formatRatio ? (image.width - width) / 2 : 0
  const y = imageRatio > formatRatio ? 0 : (image.height - height) / 2
  const xLimits= [0, x * 2]
  const yLimits = [0, y * 2]
  return { x, y, width, height, xLimits, yLimits }
}

export default function Photo({ photo, className, onCrop, size, onClick, svgRef, render = false }) {
  let template = computeTemplate(photo)
  console.log(template)
  const photoRef = useRef(null)
  const crop = photo.crop || defaultCrop(photo, template)
  let bind = useDrag(({ active, movement }) => {
    const x = Math.min(Math.max(crop.x - movement[0], crop.xLimits[0]), crop.xLimits[1])
    const y = Math.min(Math.max(crop.y - movement[1], crop.yLimits[0]), crop.yLimits[1])
    if (Date.now() % 3 === 0)
      photoRef.current.setAttribute('viewBox', `${x} ${y} ${crop.width} ${crop.height}`)
    if (!active)
      onCrop({ x, y, width: crop.width, height: crop.height, xLimits: crop.xLimits, yLimits: crop.yLimits })
  })
  if (!onCrop) bind = () => ({})
  const scale = (template.width + template.height) / 2
  const bleed = render ? 0 : template.bleed
  return (
    <svg
      ref={svgRef}
      onClick={onClick}
      className={className}
      viewBox={`${bleed} ${bleed} ${template.width - (bleed * 2)} ${template.height - (bleed * 2)}`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ width: template.width / scale * size, height: template.height / scale * size }}>
      <defs>
        {photo.filter &&
          <filter
            id={`filter-${photo.id}`}
            colorInterpolationFilters="sRGB"
            dangerouslySetInnerHTML={{ __html: photo.filter.svg }} />
        }
      </defs>
      <rect
        x={0}
        y={0}
        width={template.width}
        height={template.height}
        fill="white" />
        <svg
          {...bind()}
          ref={photoRef}
          x={template.photo.x}
          y={template.photo.y}
          width={template.photo.width}
          height={template.photo.height}
          viewBox={`${crop.x} ${crop.y} ${crop.width} ${crop.height}`}>
          <image
            filter={photo.filter && `url(#filter-${photo.id})`}
            xlinkHref={render ? photo.base64 : photo.url}
            transform={`rotate(0 ${photo.width / 2} ${photo.height / 2})`} />
        </svg>
    </svg>
  )
}
