import React, { useState, useRef, useEffect } from 'react'
import { formatPrice, computePrice } from '../../utils'
import useInterval from '@use-it/interval'
import Header from './Header'
import Photo from './Photo'
import Button from '../Button'
import Popup from '../Popup'
import Keyboard from '../Keyboard'
import Editor from './Editor'
import Sample from './Sample'
import { FiTrash2, FiEdit, FiChevronDown, FiGift, FiDelete } from 'react-icons/fi'
import { useGesture } from 'react-use-gesture'

function Quantity({ photo, applyQuantity, remove }) {
  const [open, setOpen] = useState(false)
  const [quantity, setQuantity] = useState(false)
  return (
    <>
      <div
        onClick={() => applyQuantity && setOpen(true)}
        className="bg-white pl-2 pr-1 flex justify-between items-center leading-none text-xs text-orange" style={{ fontSize: '0.65em', paddingTop: '0.12rem', paddingBottom: '0.12rem' }}>
        {photo.quantity}
        <FiChevronDown className="stroke-2 ml-1" />
      </div>
      {open &&
        <Popup close={() => setOpen(false)} title="Choisissez la quantité">
          <div className="flex justify-between my-4 w-48 px-2 items-center">
            <div className="text-3xl h-12">{quantity || ''}</div>
            <Button variant="icon" className="ml-3 transform scale-90" Icon={FiDelete} onClick={() => { setQuantity(null) }} />
          </div>
          <div className="flex flex-wrap justify-center items-center mx-16">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map(q =>
              <Button
                key={q}
                variant="icon"
                className="m-3 text-lg font-normal transform scale-125"
                onClick={() => { setQuantity(parseInt(`${quantity || ''}${q}`)) }}>
                {`${q}`}
              </Button>
            )}
          </div>
          <div className="flex mt-10">
            <Button className="m-2" onClick={() => { applyQuantity(quantity || 1); setOpen(false); setQuantity(null) }}>Valider</Button>
            <Button variant="icon" Icon={FiTrash2} className="m-2 text-white" onClick={() => { remove(); setOpen(false); setQuantity(null) }}></Button>
          </div>
        </Popup>
      }
    </>
  )
}

function PhotoContainer({ photo, update, size, log, products }) {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Photo photo={photo} size={size} onClick={() => setOpen(true)} />
      <div
        className="w-full px-2 py-1 bg-orange flex justify-between items-center text-white">
        <FiEdit className="stroke-2 h-3" onClick={() => setOpen(true)} />
        <div style={{ fontSize: '0.57rem', marginBottom: '-0.1rem' }} onClick={() => setOpen(true)}>{photo.product.name}</div>
        <Quantity
          photo={photo}
          applyQuantity={quantity => {
            update({ ...photo, quantity })
            log(`update photo quantity to ${quantity}`)
          }}
          remove={() => update({ ...photo, quantity: 0 })} />
        {open &&
          <Editor photo={photo} update={update} products={products} close={() => setOpen(false)} />
        }
      </div>
    </>
  )
}

function PromoContainer({ tryCode, promo, free, log }) {
  const [code, setCode] = useState('')
  const [error, setError] = useState(false)
  const [open, setOpen] = useState(false)
  const apply = async () => {
    log(`try promo code ${code}`)
    const res = await tryCode(code)
    if (res.error) return setError(res.error)
    setOpen(false)
  }
  if (!promo && free) return null
  return (
    <div className="fixed bottom-0 mb-4 ml-3 left-0 h-32 w-full flex justify-center items-center pointer-events-none z-20">
      {!promo &&
        <Button
          variant="icon"
          className="ml-64 mb-0"
          Icon={FiGift}
          onClick={() => setOpen(true)} />}
      {promo &&
        <Button
          variant="secondary"
          className="mb-20 mr-5 bg-opacity-30 font-normal"
          Icon={FiGift}
          onClick={() => setOpen(true)}>
          {free ? <>&nbsp;Gratuit</> : <>&nbsp;{formatPrice(-promo.amount)}</>}
        </Button>
      }
      {open &&
        <Popup close={() => setOpen(false)} title="Entrez votre code de réduction">
          <Keyboard onType={setCode} />
          <Button className="mt-6" onClick={apply}>Valider</Button>
          {error && <div>{error}</div>}
        </Popup>
      }
    </div>
  )
}
const windowHeight = () => typeof window !== `undefined` ? window.innerHeight : 0

function ScrollPane({ children, photos }) {
  const ref = useRef(null)
  const [height, setHeight] = useState(0)
  const hasDragged = React.useRef(false)
  useEffect(() => {
    setTimeout(() =>
      setHeight((ref.current && ref.current.children[0]) ? ref.current.children[0].getBoundingClientRect().height : 0)
    , 500)
  }, [photos])
  const bind = useGesture(
    {
      onDrag: ({ first, last, movement, memo }) => {
        if (first) {
          hasDragged.current = true
          memo = [ref.current.scrollTop]
        }
        if (last) {
          if (Math.abs(movement[0]) < 40 && Math.abs(movement[1]) < 40)
            hasDragged.current = false
          setTimeout(() => (hasDragged.current = false), 0)
        }
        if (memo !== undefined)
          ref.current.scroll(0, -Math.min(Math.max(-memo[0] + movement[1], -height -(windowHeight() / 2)), 0))
        return memo
      },
      onClickCapture: event => {
        if (hasDragged.current) event.stopPropagation()
      }
    }, { drag: { filterTaps: true } })
  return (
    <div
      {...bind()}
      className="w-screen h-screen overflow-y-scroll"
      ref={ref}>
      {children}
    </div>
  )
}

export default function Cart({ close, promo, photos, update, checkPhotos, tryCode, config, pay, log, code }) {
  const [limitAck, setLimitAck] = useState(false)
  useInterval(checkPhotos, 3000)
  console.log('render')
  const free = computePrice(promo, photos, config) <= 0
  const products = config.variables.products
  const prints = photos.map(p => p.quantity).reduce((curr, acc) => curr + acc, 0)
  return (
    <ScrollPane photos={photos}>
      <div className="min-h-screen p-4 pt-24 flex flex-col justify-start items-center box-content">
        <Header
          title="Galerie photos"
          subtitle="Je modifie mes photos"
          close={close} />
        {config.variables.maxPrintsPerSession && prints > config.variables.maxPrintsPerSession && !limitAck &&
          <Popup close={() => setLimitAck(true)} title={`Vous ne pouvez pas imprimer plus de ${config.variables.maxPrintsPerSession} photos`}>
            <div>Supprimer des photos avant de passer à l'étape suivante</div>
            <Button className="mt-6" onClick={() => setLimitAck(true)}>J'ai compris</Button>
          </Popup>
        }
        <div className="flex flex-wrap items-center justify-evenly mb-24 select-none">
          <div className="mt-2 mb-6 mx-16" style={{ willChange: 'transform' }}>
            <Sample size={325} code={code} />
            <div
              className="w-full px-2 py-1 bg-orange flex justify-between items-center text-white">
              <FiEdit className="stroke-2 h-3" onClick={_ => false} />
              <Quantity photo={{ quantity: 1 }} />
            </div>
          </div>
          {photos.map(photo =>
            <div key={photo.id} className="m-2">
              <PhotoContainer
                photo={photo}
                update={update}
                log={log}
                size={325}
                products={products} />
            </div>
          )}
          {photos.length === 0 &&
            <div className="h-64 flex flex-col justify-center items-center text-center">
              <div>Vous n'avez actuellement aucune photo.</div>
              <div>Ajoutez des photos depuis votre téléphone ou finalisez votre sessions avec la croix en haut à droite.</div>
            </div>
          }
        </div>
        <PromoContainer tryCode={tryCode} promo={promo} free={free} log={log} />
        {photos.length > 0 &&
          <div className="fixed bottom-0 mb-4 h-32 w-screen flex flex-col justify-center items-center pointer-events-none">
            {!free &&
              <Button
                variant="primary"
                className="z-10"
                onClick={pay}
                disabled={config.variables.maxPrintsPerSession && (prints > config.variables.maxPrintsPerSession)}>
                Payer {formatPrice(computePrice(promo, photos, config))}
              </Button>
            }
            {free &&
              <Button
                variant="primary"
                className="z-10"
                onClick={pay}>
                Imprimer
              </Button>
            }
            {config.price > 0 &&
              <span className="fixed bottom-0 text-sm mb-12" style={{ bottom: '0.25rem' }}>
                À partir de {formatPrice(config.price)} par photo
              </span>
            }
          </div>
        }
      </div>
    </ScrollPane>
  )
}
