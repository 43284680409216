import React, { useEffect, useState } from 'react'
import Title from '../Title'
import Popup from '../Popup'
import Button from '../Button'

export default function Timeout({ session, diff, endSession }) {
  const [show, setShow] = useState(false)
  useEffect(() => {
    if (!session) return
    setShow(false)
    const t = setTimeout(() => setShow(true), 15 * 60 * 1000)
    return () => clearTimeout(t)
  }, [diff])
  useEffect(() => {
    if (!show || !session) return
    const t = setTimeout(() => {
      endSession()
      setShow(false)
    }, 60 * 1000)
    return () => clearTimeout(t)
  }, [show])
  if (!show) return null
  return (
    <Popup>
      <Title>Êtes vous encore là ?</Title>
      <Button onClick={() => setShow(false)}>Oui !</Button>
    </Popup>
  )
}
