import React, { useEffect } from 'react'
import Button from './Button'

export default function Error({ children }) {
  useEffect(() => { setTimeout(() => document.location.reload(), 10000) }, [])
  return (
    <div
      className="w-screen h-screen flex flex-col justify-center items-center p-10">
      <span className="text-2xl font-normal">Erreur</span>
      <span className="text-center">
        {children}
      </span>
      <Button
        variant="primary"
        className="m-8"
        onClick={() => document.location.reload()}>
        Réessayer
      </Button>
    </div>
  )
}
