import React from 'react'
import { FiCheck } from 'react-icons/fi'

export default function Checkbox({ checked, onClick, className }) {
  return (
    <div
      onClick={onClick}
      className={`flex justify-center items-center flex-shrink-0 w-8 h-8 p-2 bg-white ${className}`}
      style={{ boxShadow: '0 0 3rem rgba(0, 0, 0, .05)', filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, .07))' }}>
      {checked && <FiCheck className="text-blue stroke-2 w-8 h-8" />}
    </div>
  )
}
