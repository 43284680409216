import React, { useState, useEffect } from 'react'
import Popup from '../Popup'
import Button from '../Button'
import Loader from '../Loader'
import { recentJobs, printerStatus, reprintJob } from '../../api'
import { FiDelete, FiRefreshCw, FiPrinter, FiHelpCircle, FiCheck } from 'react-icons/fi'
import moment from 'moment'
import { timeout } from '../../utils'

export default function Auth(props) {
  const [auth, setAuth] = useState(false)
  const [code, setCode] = useState('')

  const test = () => {
    const params = new URLSearchParams(window.location.search)
    console.log()
    if (code === params.get('admin_code'))
      setAuth(true)
  }
  if (auth)
    return (
      <div>
        <Admin {...props} />
        <div className="font-thin fixed z-30 bottom-0 mb-2 w-full text-center text-sm">Numéro SAV : 01 84 60 81 60</div>
      </div>
    )
  return (
    <Popup close={props.back} title='Code administrateur' className="">
      <div className="font-thin fixed z-30 bottom-0 mb-2 w-full text-center text-sm">Numéro SAV : 01 84 60 81 60</div>
      <div className="flex justify-between my-4 w-48 px-2 items-center">
        <div className="text-3xl h-12">{code}</div>
        <Button
          variant="icon"
          className="ml-3 transform scale-90"
          Icon={FiDelete}
          onClick={() => setCode(code.slice(0, code.length - 1))} />
      </div>
      <div className="flex flex-wrap justify-center items-center mx-16">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map(digit =>
          <Button
            key={digit}
            variant="icon"
            className="m-3 text-lg font-normal transform scale-125"
            onClick={() => setCode(`${code}${digit}`)}>
            {`${digit}`}
          </Button>
          )}
      </div>
      <div className="flex items-center mt-10">
        <Button className="m-2" onClick={test}>Valider</Button>
      </div>
  </Popup>
  )
}

function Admin({ back }) {
    const [printerInfo, setPrinterInfo] = useState()
    const [showTutorial, setShowTutorial] = useState(false)
    const [showJobs, setShowJobs] = useState(false)
    const [jobs, setJobs] = useState([])

    useEffect(() => {
      printerStatus().then(setPrinterInfo)
    }, [])

    useEffect(() => {
      if (showJobs) recentJobs().then(setJobs)
    }, [showJobs])

    const printerAlert = printerInfo?.remaining <= 50 ? 'bg-orange font-bold p-2 border-2xl text-white animate-pulse' : ''

    const reprintJobs = async () => {
      const selected = jobs.filter(j => j.selected)
      for (const job of selected) {
        await reprintJob(job.id)
        await timeout(2000)
        job.selected = false
        job.reprinted = true
      }
      setJobs([...jobs])
    }

    if (showTutorial) {
      return (
        <Popup close={() => setShowTutorial(false)} title='Changement de consommable'>
          <div className="w-full h-full flex flex-col justify-start items-center">
          <iframe
            className="mt-2 w-full" 
            width="950" 
            height="534" 
            src="https://www.youtube.com/embed/C_BQuIX_rDI" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />
          </div>
        </Popup>
      )
    }
    if (showJobs) {
      return (
        <Popup close={() => setShowJobs(false)} title="Impressions">
          <div className="w-full h-full flex flex-row flex-wrap justify-center items-center">
            {jobs && jobs.slice(0, 39).map(j =>
              <div key={j.id} className={`bg-white relative pl-6 pr-2 py-1 m-1 rounded text-sm font-thin ${j.reprinted ? 'opacity-50' : ''}`} onClick={() => { j.selected = !j.selected; setJobs([...jobs]) }}>
                {j.selected &&
                  <div className="absolute left-0 top-0 ml-2 h-full flex justify-center items-center"><FiCheck className="stroke-1" /></div>
                }
                {moment(j.date).format('DD/MM HH:mm')}
              </div>
            )}
            <Button Icon={FiPrinter} variant="secondary" disabled={!jobs.filter(j => j.selected).length} className="mt-5" onClick={reprintJobs}>Réimprimer</Button>
          </div>
        </Popup>
      )
    }
    return (
      <Popup close={back} title="Imprimante" className="">
        {printerInfo?.error &&
          <h1>{printerInfo.error}</h1>
        }
        <div className="flex justify-center items-center flex-wrap mb-4">
          <Button variant="secondary" className="mx-3 my-2" Icon={FiRefreshCw} onClick={() => printerStatus().then(setPrinterInfo)}>
            Actualiser
          </Button>
          <Button variant="secondary" className="mx-3 my-2" Icon={FiHelpCircle} onClick={() => setShowTutorial(true)}>
            Aide
          </Button>
          <Button variant="secondary" className="mx-3 my-2" Icon={FiPrinter} onClick={() => setShowJobs(true)}>
            Réimprimer
          </Button>
        </div>
        {printerInfo && !printerInfo.error && !showJobs &&
          <div className="w-full h-full flex flex-row justify-evenly flex-wrap">
            {Object.keys(printerInfo).map(k =>
              <div key={k} className="leading-tight m-2 w-32 bg-white rounded px-4 py-2">
                <div className="uppercase font-bold text-sm">{k}</div>
                <div className="text-xl font-thin">{printerInfo[k]}</div>
              </div>  
            )}
          </div>
        }
        {printerInfo?.error && <p>{printerInfo?.error}</p>}
      </Popup>
    )
}