import React from 'react'
import { layoutStyle } from '../../utils'
import useInterval from '@use-it/interval'
import Header from './Header'
import Title from '../Title'

export default function Upload({ close, checkPhotos }) {
  useInterval(checkPhotos, 2000)
  return (
    <div className={layoutStyle}>
      <Header
        title="Téléchargement"
        subtitle="J'ajoute les photos de mon téléphone"
        close={close} />
      <Title>Vous êtes connecté !</Title>
      <div className="text-center">Vous pouvez maintenant ajouter des photos depuis votre téléphone</div>
    </div>
  )
}
