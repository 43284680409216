import React from 'react'
import QRCode from 'qrcode.react'

const location = () => typeof window !== `undefined` ? window.location : { pathname: '', hostname: '', search: '' }

const url = location().hostname


export default function Sample({ size, code }) {
  const template = {
    width: 1850,
    height: 1250,
    bleed: 25,
    photo: {
      x: 0,
      y: 0,
      width: 1850,
      height: 1250
    }
  }
  const scale = (template.width + template.height) / 2
  return (
    <div
      className="relative bg-white text-xs font-thin text-blue p-2 -z-10"
      style={{ width: template.width / scale * size, height: template.height / scale * size }}>
      <svg className="absolute top-0 left-0 -mt-4 -ml-1 opacity-50" xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 22 24" fill="none" stroke="currentColor" ><polyline points="12 19 5 12 12 5"></polyline></svg>
      <svg className="absolute top-0 right-0 -mt-4 -mr-1 opacity-50" xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 26 24" fill="none" stroke="currentColor" ><polyline points="12 5 19 12 12 19"></polyline></svg>
      <svg className="absolute top-0 left-0 -ml-4 -mt-1 opacity-50" xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 24 22" fill="none" stroke="currentColor" ><polyline points="5 12 12 5 19 12"></polyline></svg>
      <svg className="absolute bottom-0 left-0 -ml-4 -mb-1 opacity-50" xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 24 26" fill="none" stroke="currentColor" ><polyline points="19 12 12 19 5 12"></polyline></svg>
      <div className="absolute top-0 left-0 -mt-6 w-full text-center" style={{ fontSize: '0.8em' }}>{Math.round((template.width-50) / 300 * 2.54)} cm</div>
      <div className="absolute top-0 left-0 -mt-2 w-full bg-blue" style={{ height: 2, top: -3.5  }} />
      <div className="absolute top-0 left-0 text-center -ml-6" style={{ fontSize: '0.8em', transform: 'rotate(-90deg) translateX(-100%)', transformOrigin: '0 0', width: `calc(100% / ${template.width / template.height})` }}>{Math.round((template.height-50) / 300 * 2.54)} cm</div>
      <div className="absolute top-0 left-0 -ml-2 w-full bg-blue" style={{ height: 2, left: -3.5, fontSize: '0.8em', transform: 'rotate(-90deg) translateX(-100%)', transformOrigin: '0 0', width: `calc(100% / ${template.width / template.height})` }} />
      <div className="absolute bottom-0 left-0 -mb-8 w-full text-right px-2" style={{ fontSize: '0.65em' }}>Quantité</div>
      <div className="absolute bottom-0 left-0 -mb-8 w-full text-left px-1" style={{ fontSize: '0.65em' }}>Zoomer</div>
      <div className="text-center flex flex-col justify-center items-center h-full leading-tight" style={{ fontSize: '0.7em' }}>
        Vous pouvez ajouter d'autres photos depuis votre téléphone
        <QRCode
          value={`${url}/?code=${code}`}
          size={128}
          level="L"
          includeMargin={false}
          fgColor="#001540"
          renderAs="svg"
          className="border-white bg-white h-12 w-10 border-8 mt-2 opacity-75" />
      </div>
      <svg
        className="absolute top-0 left-0 w-full h-full"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`${template.bleed} ${template.bleed} ${template.width - (template.bleed * 2)} ${template.height - (template.bleed* 2)}`}> 
        <defs>
          <mask id="mask">
            <rect x={0} y={0} width={template.width} height={template.height} fill="white" />
            <rect x={template.photo.x} y={template.photo.y} width={template.photo.width} height={template.photo.height} color="black" />
          </mask>
        </defs>
        <rect
          x={0}
          y={0}
          width={template.width}
          height={template.height}
          fill="white" 
          mask="url(#mask)"
          fillOpacity="0.4"/>
      </svg>
    </div>
  )
}
