import React, { useState } from 'react'
import { FiX } from 'react-icons/fi'
import Button from '../Button'
import Popup from '../Popup'
import Title from '../Title'

export default function Header({ title, subtitle, close }) {
  const [confirm, setConfirm] = useState(false)
  return (
    <>
      <div
        className="fixed top-0 left-0 w-screen flex justify-between items-center pl-8 pr-4 py-4 bg-blue z-10 text-white">
        <div className="leading-6">
          <Title style={{ marginBottom: 0 }} className="font-bold">{title}</Title>
          {/* <div className="font-thin">{subtitle}</div> */}
        </div>
        {close && <FiX onClick={() => setConfirm(true)} className="h-full w-8 stroke-1" />}
      </div>
      {confirm &&
        <Popup close={() => setConfirm(false)}>
          <Title>Êtes-vous sûr de vouloir abandonner votre commande ?</Title>
          <div className="flex mt-2">
            <Button className="mx-4" onClick={close}>J'abandonne</Button>
            <Button className="mx-4" onClick={() => setConfirm(false)}>Je continue</Button>
          </div>
        </Popup>
      }
    </>
  )
}
