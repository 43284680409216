export const filters = [
  {
    id: 1,
    name: 'Original',
    svg: `
      <feComponentTransfer in="SourceGraphic">
        <feFuncR type="identity"/>
        <feFuncG type="identity"/>
        <feFuncB type="identity"/>
      </feComponentTransfer>
    `
  },
  {
    id: 2,
    name: 'N&B',
    svg: `
      <feColorMatrix in="SourceGraphic" type="saturate" values="0" result="blackAndWhite" />
      <feComponentTransfer in="blackAndWhite">
        <feFuncR type="table" tableValues=".01 .25 .82 1" />
        <feFuncG type="table" tableValues=".01 .25 .82 1" />
        <feFuncB type="table" tableValues=".01 .25 .82 1" />
      </feComponentTransfer>    
    `
  },
  {
    id: 3,
    name: 'Rétro',
    svg: `
      <feComponentTransfer in="SourceGraphic">
        <feFuncR type="table" tableValues=".01 .20 .85 1" />
        <feFuncG type="table" tableValues=".1  .20 .70 1" />
        <feFuncB type="table" tableValues=".2  .35 .65 .9" />
      </feComponentTransfer>
    `
  },
  {
    id: 4,
    name: 'Contrasté',
    svg: `
      <feComponentTransfer in="SourceGraphic">
        <feFuncR type="table" tableValues=".01 .26 .79 1" />
        <feFuncG type="table" tableValues=".01 .26 .79 1" />
        <feFuncB type="table" tableValues=".02 .26 .79 1" />
      </feComponentTransfer>
    `
  },
  {
    id: 5,
    name: 'Atténué',
    svg: `
      <feComponentTransfer in="SourceGraphic">
        <feFuncR type="table" tableValues=".01 .40 .8 1" />
        <feFuncG type="table" tableValues=".01 .40 .8 1" />
        <feFuncB type="table" tableValues=".015 .42 .8 1" />
      </feComponentTransfer>
      <feColorMatrix type="saturate" values="1.05" />
    `
  }
]